import React from 'react';
import { NavBar } from './NavBar';

export const Contact = () => {
    
    return (
        <>
            <NavBar />
            <div className="contact" >
                <p><b>Email: </b>ercfgang@gmail.com</p>
            </div>
        </>

    )
};