import React from 'react';
import { NavBar } from './NavBar';

export const About = () => {
    
    return (
        <>
            <NavBar />
        </>

    )
};