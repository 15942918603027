const ercf = [
    "Shay Jin", 
    "Billy Boi", 
    "sagikun", 
    "Mogi", 
    "MST", 
    "UnteIl", 
    "BIG Naugty", 
    "김진성", 
    "gibongi",
    "Juts5Bell", 
    "unujangay", 
    "KESH B", 
];

export default ercf;